import LuCity from './LuCity'
import Contact from './Contact'
import LuCounty from './LuCounty'
import LuState from './LuState'
import Application from './Application'
export default class TaxableProperty {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //ContactId is required
      ContactId: 0,
      StreetAddress1: null,
      StreetAddress2: null,
      CityId: null,
      CountyId: null,
      StateId: 50,
      //IsActive is required
      IsActive: true,
      Zip: null,
      ParcelId: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.ContactId = obj.ContactId
    this.StreetAddress1 = obj.StreetAddress1
    this.StreetAddress2 = obj.StreetAddress2
    this.CityId = obj.CityId
    this.CountyId = obj.CountyId
    this.StateId = obj.StateId
    this.IsActive = obj.IsActive
    this.Zip = obj.Zip
    this.ParcelId = obj.ParcelId
    this.City = new LuCity(obj.City || {})
    this.Contact = new Contact(obj.Contact || {})
    this.County = new LuCounty(obj.County || {})
    this.State = new LuState(obj.State || {})
    this.Applications = []
    if (obj.Applications) {
      obj.Applications.forEach((item) => {
        this.Applications.push(new Application(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.City = null
    obj.Contact = null
    obj.County = null
    obj.State = null
    obj.Applications = []
    if (this.Applications) {
      this.Applications.forEach((item) => {
        obj.Applications.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.City = null
    obj.Contact = null
    obj.County = null
    obj.State = null
    obj.Applications = null
    return obj
  }
}
