import LuPeriod from './LuPeriod'
import TaxableProperty from './TaxableProperty'
import ApplicationDocument from './ApplicationDocument'
import ApplicationNote from './ApplicationNote'
import ApplicationStatusHistory from './ApplicationStatusHistory'
import HouseholdMember from './HouseholdMember'
export default class Application {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      ApplicationNumber: null,
      PostmarkDate: null,
      //PeriodId is required
      PeriodId: 0,
      //TaxablePropertyId is required
      TaxablePropertyId: 0,
      TaxBillAmount: null,
      BusinessDeduction: 0.00,
      Payment1Amount: null,
      Payment1Date: null,
      Payment2Amount: null,
      Payment2Date: null,
      TotalHouseholdIncomeReported: null,
      IsPrimaryResidence: false,
      IsTaxBillAttached: false,
      IsPaymentReceiptAttached: false,
      AreHouseholdDetailsProvided: false,
      DoResidentialRequirementsQualify: false,
      DoHouseholdAssetsQualify: false,
      PropertyTaxGreaterThanIncomePercentage: false,
      IsIrsAttached: false,
      IsApplicationSigned: false,
      CertifySsn: false,
      NoBackupWithholding: false,
      UsResident: false,
      CorrectAndComplete: false,
      RefundAmount: null,
      RefundStatusDate: null,
      //CreatedById is required
      CreatedById: 0,
      //CreatedDate is required
      CreatedDate: new Date().toISOString().split('T')[0],
      //UpdatedById is required
      UpdatedById: 0,
      //UpdatedDate is required
      UpdatedDate: new Date().toISOString().split('T')[0],
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.ApplicationNumber = obj.ApplicationNumber
    this.PostmarkDate = obj.PostmarkDate
    this.PeriodId = obj.PeriodId
    this.TaxablePropertyId = obj.TaxablePropertyId
    this.TaxBillAmount = obj.TaxBillAmount
    this.BusinessDeduction = obj.BusinessDeduction
    this.Payment1Amount = obj.Payment1Amount
    this.Payment1Date = obj.Payment1Date
    this.Payment2Amount = obj.Payment2Amount
    this.Payment2Date = obj.Payment2Date
    this.TotalHouseholdIncomeReported = obj.TotalHouseholdIncomeReported
    this.IsPrimaryResidence = obj.IsPrimaryResidence
    this.IsTaxBillAttached = obj.IsTaxBillAttached
    this.IsPaymentReceiptAttached = obj.IsPaymentReceiptAttached
    this.AreHouseholdDetailsProvided = obj.AreHouseholdDetailsProvided
    this.DoResidentialRequirementsQualify = obj.DoResidentialRequirementsQualify
    this.DoHouseholdAssetsQualify = obj.DoHouseholdAssetsQualify
    this.PropertyTaxGreaterThanIncomePercentage = obj.PropertyTaxGreaterThanIncomePercentage
    this.IsIrsAttached = obj.IsIrsAttached
    this.IsApplicationSigned = obj.IsApplicationSigned
    this.CertifySsn = obj.CertifySsn
    this.NoBackupWithholding = obj.NoBackupWithholding
    this.UsResident = obj.UsResident
    this.CorrectAndComplete = obj.CorrectAndComplete
    this.RefundAmount = obj.RefundAmount
    this.RefundStatusDate = obj.RefundStatusDate
    this.CreatedById = obj.CreatedById
    this.CreatedDate = obj.CreatedDate
    this.UpdatedById = obj.UpdatedById
    this.UpdatedDate = obj.UpdatedDate
    this.CreatedBy = obj.CreatedBy || {}
    this.Period = new LuPeriod(obj.Period || {})
    this.TaxableProperty = new TaxableProperty(obj.TaxableProperty || {})
    this.UpdatedBy = obj.UpdatedBy || {}
    this.ApplicationDocuments = []
    if (obj.ApplicationDocuments) {
      obj.ApplicationDocuments.forEach((item) => {
        this.ApplicationDocuments.push(new ApplicationDocument(item))
      })
    }
    this.ApplicationNotes = []
    if (obj.ApplicationNotes) {
      obj.ApplicationNotes.forEach((item) => {
        this.ApplicationNotes.push(new ApplicationNote(item))
      })
    }
    this.ApplicationStatusHistories = []
    if (obj.ApplicationStatusHistories) {
      obj.ApplicationStatusHistories.forEach((item) => {
        this.ApplicationStatusHistories.push(new ApplicationStatusHistory(item))
      })
    }
    this.HouseholdMembers = []
    if (obj.HouseholdMembers) {
      obj.HouseholdMembers.forEach((item) => {
        this.HouseholdMembers.push(new HouseholdMember(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.CreatedBy = null
    obj.Period = null
    obj.TaxableProperty = null
    obj.UpdatedBy = null
    obj.ApplicationDocuments = []
    if (this.ApplicationDocuments) {
      this.ApplicationDocuments.forEach((item) => {
        obj.ApplicationDocuments.push(item.removeRelated())
      })
    }
    obj.ApplicationNotes = []
    if (this.ApplicationNotes) {
      this.ApplicationNotes.forEach((item) => {
        obj.ApplicationNotes.push(item.removeRelated())
      })
    }
    obj.ApplicationStatusHistories = []
    if (this.ApplicationStatusHistories) {
      this.ApplicationStatusHistories.forEach((item) => {
        obj.ApplicationStatusHistories.push(item.removeRelated())
      })
    }
    obj.HouseholdMembers = []
    if (this.HouseholdMembers) {
      this.HouseholdMembers.forEach((item) => {
        obj.HouseholdMembers.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.CreatedBy = null
    obj.Period = null
    obj.TaxableProperty = null
    obj.UpdatedBy = null
    obj.ApplicationDocuments = null
    obj.ApplicationNotes = null
    obj.ApplicationStatusHistories = null
    obj.HouseholdMembers = null
    return obj
  }
}
