import Contact from './Contact'
import UserRole from './UserRole'
export default class User {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      FirstName: null,
      LastName: null,
      Email: null,
      IsActive: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.FirstName = obj.FirstName
    this.LastName = obj.LastName
    this.Email = obj.Email
    this.IsActive = obj.IsActive
    this.Contacts = []
    if (obj.Contacts) {
      obj.Contacts.forEach((item) => {
        this.Contacts.push(new Contact(item))
      })
    }
    this.UserRoles = []
    if (obj.UserRoles) {
      obj.UserRoles.forEach((item) => {
        this.UserRoles.push(new UserRole(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Contacts = []
    if (this.Contacts) {
      this.Contacts.forEach((item) => {
        obj.Contacts.push(item.removeRelated())
      })
    }
    obj.UserRoles = []
    if (this.UserRoles) {
      this.UserRoles.forEach((item) => {
        obj.UserRoles.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Contacts = null
    obj.UserRoles = null
    return obj
  }
}
