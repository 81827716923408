export default class LuRole {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Description is required
      Description: '',
      IsActive: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Description = obj.Description
    this.IsActive = obj.IsActive
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    return obj
  }
}
