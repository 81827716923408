import ApplicationStatusHistory from './ApplicationStatusHistory'
import LuReason from './LuReason'
import NextAvailableStatus from './NextAvailableStatus'
import StatusTemplate from './StatusTemplate'
export default class LuApplicationStatus {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Description is required
      Description: '',
      StatusCode: '',
      //IsActive is required
      IsActive: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Description = obj.Description
    this.StatusCode = obj.StatusCode
    this.IsActive = obj.IsActive
    this.ApplicationStatusHistories = []
    if (obj.ApplicationStatusHistories) {
      obj.ApplicationStatusHistories.forEach((item) => {
        this.ApplicationStatusHistories.push(new ApplicationStatusHistory(item))
      })
    }
    this.LuReasons = []
    if (obj.LuReasons) {
      obj.LuReasons.forEach((item) => {
        this.LuReasons.push(new LuReason(item))
      })
    }
    this.NextAvailableStatuses = []
    if (obj.NextAvailableStatuses) {
      obj.NextAvailableStatuses.forEach((item) => {
        this.NextAvailableStatuses.push(new NextAvailableStatus(item))
      })
    }
    this.StatusTemplates = []
    if (obj.StatusTemplates) {
      obj.StatusTemplates.forEach((item) => {
        this.StatusTemplates.push(new StatusTemplate(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.ApplicationStatusHistories = []
    if (this.ApplicationStatusHistories) {
      this.ApplicationStatusHistories.forEach((item) => {
        obj.ApplicationStatusHistories.push(item.removeRelated())
      })
    }
    obj.LuReasons = []
    if (this.LuReasons) {
      this.LuReasons.forEach((item) => {
        obj.LuReasons.push(item.removeRelated())
      })
    }
    obj.NextAvailableStatuses = []
    if (this.NextAvailableStatuses) {
      this.NextAvailableStatuses.forEach((item) => {
        obj.NextAvailableStatuses.push(item.removeRelated())
      })
    }
    obj.StatusTemplates = []
    if (this.StatusTemplates) {
      this.StatusTemplates.forEach((item) => {
        obj.StatusTemplates.push(item.removeRelated())
      })
    }
    return obj
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.ApplicationStatusHistories = null
    obj.LuReasons = null
    obj.NextAvailableStatuses = null
    if (this.StatusTemplates) {
      obj.StatusTemplates = []
      this.StatusTemplates.forEach((item) => {
        obj.StatusTemplates.push(item.removeRelated())
      })
    } else {
      obj.StatusTemplates = null
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.ApplicationStatusHistories = null
    obj.LuReasons = null
    obj.NextAvailableStatuses = null
    obj.StatusTemplates = null

    return obj
  }
}
