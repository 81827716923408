import Application from './Application'
import LuApplicationStatus from './LuApplicationStatus'
import ApplicationStatusReason from './ApplicationStatusReason'
export default class ApplicationStatusHistory {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //ApplicationId is required
      ApplicationId: 0,
      //StatusId is required
      StatusId: 0,
      //CreatedById is required
      CreatedById: 0,
      //CreatedDate is required
      CreatedDate: new Date().toISOString().split('T')[0],
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.ApplicationId = obj.ApplicationId
    this.StatusId = obj.StatusId
    this.CreatedById = obj.CreatedById
    this.CreatedDate = obj.CreatedDate
    this.Application = new Application(obj.Application || {})
    this.CreatedBy = obj.CreatedBy || {}
    this.Status = new LuApplicationStatus(obj.Status || {})
    this.ApplicationStatusReasons = []
    if (obj.ApplicationStatusReasons) {
      obj.ApplicationStatusReasons.forEach((item) => {
        this.ApplicationStatusReasons.push(new ApplicationStatusReason(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Application = null
    obj.CreatedBy = null
    obj.Status = null
    obj.ApplicationStatusReasons = []
    if (this.ApplicationStatusReasons) {
      this.ApplicationStatusReasons.forEach((item) => {
        obj.ApplicationStatusReasons.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Application = null
    obj.CreatedBy = null
    obj.Status = null
    obj.ApplicationStatusReasons = null
    return obj
  }
}
