import CityCounty from './CityCounty'
import CountyMedian from './CountyMedian'
import TaxableProperty from './TaxableProperty'
import LuCity from './LuCity'
export default class LuCounty {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Name is required
      Name: '',
      IsActive: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Name = obj.Name
    this.IsActive = obj.IsActive
    this.CityCounties = []
    if (obj.CityCounties) {
      obj.CityCounties.forEach((item) => {
        this.CityCounties.push(new CityCounty(item))
      })
    }
    this.CountyMedians = []
    if (obj.CountyMedians) {
      obj.CountyMedians.forEach((item) => {
        this.CountyMedians.push(new CountyMedian(item))
      })
    }
    this.TaxableProperties = []
    if (obj.TaxableProperties) {
      obj.TaxableProperties.forEach((item) => {
        this.TaxableProperties.push(new TaxableProperty(item))
      })
    }
    this.Cities = []
    if (obj.Cities) {
      obj.Cities.forEach((item) => {
        this.Cities.push(new LuCity(item))
      })
    }
  }
  removeRelated () {
    let obj = Object.assign({}, this)
    obj.CityCounties = []
    if (this.CityCounties) {
      this.CityCounties.forEach((item) => {
        obj.CityCounties.push(item.removeRelated())
      })
    }
    obj.CountyMedians = []
    if (this.CountyMedians) {
      this.CountyMedians.forEach((item) => {
        obj.CountyMedians.push(item.removeRelated())
      })
    }
    obj.TaxableProperties = []
    if (this.TaxableProperties) {
      this.TaxableProperties.forEach((item) => {
        obj.TaxableProperties.push(item.removeRelated())
      })
    }
    obj.Cities = []
    if (this.Cities) {
      this.Cities.forEach((item) => {
        obj.Cities.push(item instanceof LuCity ? item.removeRelated() : item)
      })
    }
    return obj
  }
  root () {
    let obj = Object.assign({}, this)
    obj.CityCounties = null
    obj.CountyMedians = null
    obj.TaxableProperties = null
    return obj
  }
}
