import Contact from './Contact'
import TaxableProperty from './TaxableProperty'
export default class LuState {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Abbr is required
      Abbr: '',
      //Name is required
      Name: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Abbr = obj.Abbr
    this.Name = obj.Name
    this.Contacts = []
    if (obj.Contacts) {
      obj.Contacts.forEach((item) => {
        this.Contacts.push(new Contact(item))
      })
    }
    this.TaxableProperties = []
    if (obj.TaxableProperties) {
      obj.TaxableProperties.forEach((item) => {
        this.TaxableProperties.push(new TaxableProperty(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Contacts = []
    if (this.Contacts) {
      this.Contacts.forEach((item) => {
        obj.Contacts.push(item.removeRelated())
      })
    }
    obj.TaxableProperties = []
    if (this.TaxableProperties) {
      this.TaxableProperties.forEach((item) => {
        obj.TaxableProperties.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Contacts = null
    obj.TaxableProperties = null
    return obj
  }
}
