import LuApplicationStatus from './LuApplicationStatus'
import LuValidation from './LuValidation'
import ApplicationStatusReason from './ApplicationStatusReason'
const defaults = {
  //Id is required
  Id: 0,
  //Description is required
  Description: '',
  //IsActive: true, //sql default
  //IsActive is required
  IsActive: true, //sql default
  //StatusId is required
  StatusId: null,
  NextReasonId: null,
  NextReasonDays: null,
  ReasonNumber: null,
  ValidationId: null,
}

export default class LuReason {
  constructor(obj = {}) {
    Object.assign(this, defaults, obj)
    this.NextReason = obj.NextReason || {}
    this.Status = new LuApplicationStatus(obj.Status || {})
    this.Validation = new LuValidation(obj.Validation || {})
    this.ApplicationStatusReasons = []
    if (obj.ApplicationStatusReasons) {
      obj.ApplicationStatusReasons.forEach((item) => {
        this.ApplicationStatusReasons.push(new ApplicationStatusReason(item))
      })
    }
    this.InverseNextReason = []
    if (obj.InverseNextReason) {
      obj.InverseNextReason.forEach((item) => {
        this.InverseNextReason.push(new LuReason(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.NextReason = null
    obj.Status = null
    obj.Validation =
      this.Validation instanceof LuValidation
        ? this.Validation.removeRelated()
        : null
    obj.ApplicationStatusReasons = []
    if (this.ApplicationStatusReasons) {
      this.ApplicationStatusReasons.forEach((item) => {
        obj.ApplicationStatusReasons.push(
          item instanceof ApplicationStatusReason ? item.removeRelated() : item
        )
      })
    }
    obj.InverseNextReason = []
    if (this.InverseNextReason) {
      this.InverseNextReason.forEach((item) => {
        obj.InverseNextReason.push(
          item instanceof LuReason ? item.removeRelated() : item
        )
      })
    }
    return this.equalsDefault() ? null : obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.NextReason = null
    obj.Status = null
    obj.Validation = null
    obj.ApplicationStatusReasons = null
    obj.InverseNextReason = null
    return this.equalsDefault() ? null : obj
  }
  equalsDefault() {
    let obj = Object.assign({}, this)
    let compare = {
      Id: obj.Id,
      Description: obj.Description,
      IsActive: obj.IsActive,
      StatusId: obj.StatusId,
      NextReasonId: obj.NextReasonId,
      NextReasonDays: obj.NextReasonDays,
      ReasonNumber: obj.ReasonNumber,
      ValidationId: obj.ValidationId,
    }
    return JSON.stringify(defaults) === JSON.stringify(compare)
  }
}
