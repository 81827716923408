import Application from './Application'
import LuNoteType from './LuNoteType'
export default class ApplicationNote {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //ApplicationId is required
      ApplicationId: 0,
      //NoteTypeId is required
      NoteTypeId: 1,
      Note: null,
      //CreatedById is required
      CreatedById: 0,
      CreatedDate: null,
      //UpdatedById is required
      UpdatedById: 0,
      UpdatedDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.ApplicationId = obj.ApplicationId
    this.NoteTypeId = obj.NoteTypeId
    this.Note = obj.Note
    this.CreatedById = obj.CreatedById
    this.CreatedDate = obj.CreatedDate
    this.UpdatedById = obj.UpdatedById
    this.UpdatedDate = obj.UpdatedDate
    this.Application = new Application(obj.Application || {})
    this.CreatedBy = obj.CreatedBy || {}
    this.NoteType = new LuNoteType(obj.NoteType || {})
    this.UpdatedBy = obj.UpdatedBy || {}
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Application = null
    obj.CreatedBy = null
    obj.NoteType = null
    obj.UpdatedBy = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Application = null
    obj.CreatedBy = null
    obj.NoteType = null
    obj.UpdatedBy = null
    return obj
  }
}
