import LuApplicationStatus from './LuApplicationStatus'
import AvailableStatusRole from './AvailableStatusRole'
export default class NextAvailableStatus {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //CurrentStatusId is required
      CurrentStatusId: 0,
      //NextStatusId is required
      NextStatusId: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.CurrentStatusId = obj.CurrentStatusId
    this.NextStatusId = obj.NextStatusId
    this.CurrentStatus = new LuApplicationStatus(obj.CurrentStatus || {})
    this.NextStatus = new LuApplicationStatus(obj.NextStatus || {})
    this.AvailableStatusRoles = []
    if (obj.AvailableStatusRoles) {
      obj.AvailableStatusRoles.forEach((item) => {
        this.AvailableStatusRoles.push(new AvailableStatusRole(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.CurrentStatus = null
    obj.NextStatus = null
    obj.AvailableStatusRoles = []
    if (this.AvailableStatusRoles) {
      this.AvailableStatusRoles.forEach((item) => {
        obj.AvailableStatusRoles.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.CurrentStatus = null
    obj.NextStatus = null
    obj.AvailableStatusRoles = null
    return obj
  }
}
