import Application from './Application'
import Document from './Document'
import HouseholdMember from './HouseholdMember'
export default class ApplicationDocument {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //ApplicationId is required
      ApplicationId: 0,
      //DocumentId is required
      DocumentId: 0,
      HouseholdMemberId: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.ApplicationId = obj.ApplicationId
    this.DocumentId = obj.DocumentId
    this.HouseholdMemberId = obj.HouseholdMemberId
    this.Application = new Application(obj.Application || {})
    this.Document = new Document(obj.Document || {})
    this.HouseholdMember = new HouseholdMember(obj.HouseholdMember || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Application = null
    obj.Document = null
    obj.HouseholdMember = null
    return obj
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.Application = null
    obj.HouseholdMember = null
    obj.Document = this.Document.root()
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Application = null
    obj.Document = null
    obj.HouseholdMember = null
    return obj
  }
}
