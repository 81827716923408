import ApplicationDocumentType from './ApplicationDocumentType'
import Document from './Document'
export default class LuDocumentType {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Description is required
      Description: '',
      //InternalOnly is required
      InternalOnly: false,
      //IsActive is required
      IsActive: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Description = obj.Description
    this.InternalOnly = obj.InternalOnly
    this.IsActive = obj.IsActive
    this.ApplicationDocumentTypes = []
    if (obj.ApplicationDocumentTypes) {
      obj.ApplicationDocumentTypes.forEach((item) => {
        this.ApplicationDocumentTypes.push(new ApplicationDocumentType(item))
      })
    }
    this.Documents = []
    if (obj.Documents) {
      obj.Documents.forEach((item) => {
        this.Documents.push(new Document(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.ApplicationDocumentTypes = []
    if (this.ApplicationDocumentTypes) {
      this.ApplicationDocumentTypes.forEach((item) => {
        obj.ApplicationDocumentTypes.push(item.removeRelated())
      })
    }
    obj.Documents = []
    if (this.Documents) {
      this.Documents.forEach((item) => {
        obj.Documents.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.ApplicationDocumentTypes = null
    obj.Documents = null
    return obj
  }
}
