import LuDocumentType from './LuDocumentType'
export default class ApplicationDocumentType {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //DocumentTypeId is required
      DocumentTypeId: 0,
      //IsRequired is required
      IsRequired: false,
      IsForHouseholdMember: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.DocumentTypeId = obj.DocumentTypeId
    this.IsRequired = obj.IsRequired
    this.IsForHouseholdMember = obj.IsForHouseholdMember
    this.DocumentType = new LuDocumentType(obj.DocumentType || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.DocumentType = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.DocumentType = null
    return obj
  }
}
