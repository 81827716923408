import LuRole from './LuRole'
import User from './User'
export default class UserRole {
  constructor(objIn = {}) {
    const defaults = {
      //UserId is required
      UserId: 0,
      //RoleId is required
      RoleId: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.UserId = obj.UserId
    this.RoleId = obj.RoleId
    this.Role = new LuRole(obj.Role || {})
    this.User = new User(obj.User || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Role = null
    obj.User = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Role = null
    obj.User = null
    return obj
  }
}
