import Application from './Application'
import CountyMedian from './CountyMedian'
import FundingAppropriation from './FundingAppropriation'
export default class LuPeriod {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Period is required
      Period: 0,
      //ApplicationDeadline is required
      ApplicationDeadline: new Date().toISOString().split('T')[0],
      //Payment1Deadline is required
      Payment1Deadline: new Date().toISOString().split('T')[0],
      //Payment2Deadline is required
      Payment2Deadline: new Date().toISOString().split('T')[0],
      //AssetMaximum is required
      AssetMaximum: 0,
      //HouseholdMaximum is required
      HouseholdMaximum: 0,
      //FullPaymentDeadline is required
      FullPaymentDeadline: new Date().toISOString().split('T')[0],
      //ApplicationOpenDate is required
      ApplicationOpenDate: new Date().toISOString().split('T')[0],
      BudgetFiscalYear: null,
      PeriodEndDate: new Date().toISOString().split('T')[0],
      PercentageEligible: 0.5,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Period = obj.Period
    this.ApplicationDeadline = obj.ApplicationDeadline
    this.Payment1Deadline = obj.Payment1Deadline
    this.Payment2Deadline = obj.Payment2Deadline
    this.AssetMaximum = obj.AssetMaximum
    this.HouseholdMaximum = obj.HouseholdMaximum
    this.FullPaymentDeadline = obj.FullPaymentDeadline
    this.ApplicationOpenDate = obj.ApplicationOpenDate
    this.BudgetFiscalYear = obj.BudgetFiscalYear
    this.PeriodEndDate = obj.PeriodEndDate
    this.PercentageEligible = obj.PercentageEligible
    this.Applications = []
    if (obj.Applications) {
      obj.Applications.forEach((item) => {
        this.Applications.push(new Application(item))
      })
    }
    this.CountyMedians = []
    if (obj.CountyMedians) {
      obj.CountyMedians.forEach((item) => {
        this.CountyMedians.push(new CountyMedian(item))
      })
    }
    this.FundingAppropriations = []
    if (obj.FundingAppropriations) {
      obj.FundingAppropriations.forEach((item) => {
        this.FundingAppropriations.push(new FundingAppropriation(item))
      })
    }
  }
  removeRelated () {
    let obj = Object.assign({}, this)
    obj.Applications = []
    if (this.Applications) {
      this.Applications.forEach((item) => {
        obj.Applications.push(item.removeRelated())
      })
    }
    obj.CountyMedians = []
    if (this.CountyMedians) {
      this.CountyMedians.forEach((item) => {
        obj.CountyMedians.push(item.removeRelated())
      })
    }
    obj.FundingAppropriations = []
    if (this.FundingAppropriations) {
      this.FundingAppropriations.forEach((item) => {
        obj.FundingAppropriations.push(item.removeRelated())
      })
    }
    return obj
  }
  root () {
    let obj = Object.assign({}, this)
    obj.Applications = null
    obj.CountyMedians = null
    obj.FundingAppropriations = null
    return obj
  }
}
