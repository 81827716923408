import LuApplicationStatus from './LuApplicationStatus'
import LuTemplateDocument from './LuTemplateDocument'
export default class StatusTemplate {
  constructor(objIn = {}) {
    const defaults = {
      //TemplateId is required
      TemplateId: 0,
      //StatusId is required
      StatusId: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.TemplateId = obj.TemplateId
    this.StatusId = obj.StatusId
    this.Status = new LuApplicationStatus(obj.Status || {})
    this.Template = new LuTemplateDocument(obj.Template || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Status = null
    obj.Template = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Status = null
    obj.Template = null
    return obj
  }
}
