import WolfsItem from './WolfsItem'
export default class WolfsRun {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //RunDate is required
      RunDate: new Date().toISOString().split('T')[0],
      ItemCount: null,
      TotalValue: null,
      //CreatedById is required
      CreatedById: 0,
      //IsDisbursed is required
      IsDisbursed: false,
      //IsCanceled is required
      IsCanceled: false,
      UpdatedById: null,
      UpdatedDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.RunDate = obj.RunDate
    this.ItemCount = obj.ItemCount
    this.TotalValue = obj.TotalValue
    this.CreatedById = obj.CreatedById
    this.IsDisbursed = obj.IsDisbursed
    this.IsCanceled = obj.IsCanceled
    this.UpdatedById = obj.UpdatedById
    this.UpdatedDate = obj.UpdatedDate
    this.CreatedBy = obj.CreatedBy || {}
    this.UpdatedBy = obj.UpdatedBy || {}
    this.WolfsItems = []
    if (obj.WolfsItems) {
      obj.WolfsItems.forEach((item) => {
        this.WolfsItems.push(new WolfsItem(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.CreatedBy = null
    obj.UpdatedBy = null
    obj.WolfsItems = []
    if (this.WolfsItems) {
      this.WolfsItems.forEach((item) => {
        obj.WolfsItems.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.CreatedBy = null
    obj.UpdatedBy = null
    obj.WolfsItems = null
    return obj
  }
}
