import LuState from './LuState'
import User from './User'
import TaxableProperty from './TaxableProperty'
export default class Contact {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      UserId: null,
      FirstName: null,
      MiddleInitial: null,
      LastName: null,
      Ssn: null,
      MailingAddress1: null,
      MailingAddress2: null,
      City: null,
      StateId: 50,
      Zip: null,
      Phone: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.UserId = obj.UserId
    this.FirstName = obj.FirstName
    this.MiddleInitial = obj.MiddleInitial
    this.LastName = obj.LastName
    this.Ssn = obj.Ssn
    this.MailingAddress1 = obj.MailingAddress1
    this.MailingAddress2 = obj.MailingAddress2
    this.City = obj.City
    this.StateId = obj.StateId
    this.Zip = obj.Zip
    this.Phone = obj.Phone
    this.State = new LuState(obj.State || {})
    this.User = new User(obj.User || {})
    this.TaxableProperties = []
    if (obj.TaxableProperties) {
      obj.TaxableProperties.forEach((item) => {
        this.TaxableProperties.push(new TaxableProperty(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.State = null
    obj.User = null
    obj.TaxableProperties = []
    if (this.TaxableProperties) {
      this.TaxableProperties.forEach((item) => {
        obj.TaxableProperties.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.State = null
    obj.User = null
    obj.TaxableProperties = null
    return obj
  }
}
