import ApplicationStatusHistory from './ApplicationStatusHistory'
import LuReason from './LuReason'
export default class ApplicationStatusReason {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //ApplicationStatusHistoryId is required
      ApplicationStatusHistoryId: 0,
      //ReasonId is required
      ReasonId: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.ApplicationStatusHistoryId = obj.ApplicationStatusHistoryId
    this.ReasonId = obj.ReasonId
    this.ApplicationStatusHistory = new ApplicationStatusHistory(obj.ApplicationStatusHistory || {})
    this.Reason = new LuReason(obj.Reason || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.ApplicationStatusHistory = null
    obj.Reason = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.ApplicationStatusHistory = null
    obj.Reason = null
    return obj
  }
}
