// import Application from './Application'
import LuFundingSource from './LuFundingSource'
import WolfsRun from './WolfsRun'
export default class WolfsItem {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //WolfsRunId is required
      WolfsRunId: 0,
      //ApplicationId is required
      ApplicationId: 0,
      //FundingSourceId is required
      FundingSourceId: 0,
      //BudgetFiscalYear is required
      BudgetFiscalYear: 0,
      //DocumentNumber is required
      DocumentNumber: '',
      //LegalName is required
      LegalName: '',
      //Address1 is required
      Address1: '',
      Address2: null,
      //City is required
      City: '',
      State: null,
      Zip: null,
      //AgencyCode is required
      AgencyCode: '',
      //FundCode is required
      FundCode: '',
      ObjectCode: null,
      SubObjectCode: null,
      UnitCode: null,
      AppropriationUnit: null,
      ActivityCode: null,
      //LineAmount is required
      LineAmount: 0,
      //CreatedById is required
      CreatedById: 0,
      //CreatedDate is required
      CreatedDate: new Date().toISOString().split('T')[0],
      UpdatedById: null,
      UpdatedDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.WolfsRunId = obj.WolfsRunId
    this.ApplicationId = obj.ApplicationId
    this.FundingSourceId = obj.FundingSourceId
    this.BudgetFiscalYear = obj.BudgetFiscalYear
    this.DocumentNumber = obj.DocumentNumber
    this.LegalName = obj.LegalName
    this.Address1 = obj.Address1
    this.Address2 = obj.Address2
    this.City = obj.City
    this.State = obj.State
    this.Zip = obj.Zip
    this.AgencyCode = obj.AgencyCode
    this.FundCode = obj.FundCode
    this.ObjectCode = obj.ObjectCode
    this.SubObjectCode = obj.SubObjectCode
    this.UnitCode = obj.UnitCode
    this.AppropriationUnit = obj.AppropriationUnit
    this.ActivityCode = obj.ActivityCode
    this.LineAmount = obj.LineAmount
    this.CreatedById = obj.CreatedById
    this.CreatedDate = obj.CreatedDate
    this.UpdatedById = obj.UpdatedById
    this.UpdatedDate = obj.UpdatedDate
    this.Application = obj.Application || {}
    this.CreatedBy = obj.CreatedBy || {}
    this.FundingSource = new LuFundingSource(obj.FundingSource || {})
    this.UpdatedBy = obj.UpdatedBy || {}
    this.WolfsRun = new WolfsRun(obj.WolfsRun || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Application = null
    obj.CreatedBy = null
    obj.FundingSource = null
    obj.UpdatedBy = null
    obj.WolfsRun = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Application = null
    obj.CreatedBy = null
    obj.FundingSource = null
    obj.UpdatedBy = null
    obj.WolfsRun = null
    return obj
  }
}
