import NextAvailableStatus from './NextAvailableStatus'
import LuRole from './LuRole'
export default class AvailableStatusRole {
  constructor(objIn = {}) {
    const defaults = {
      //AvailableStatusId is required
      AvailableStatusId: 0,
      //RoleId is required
      RoleId: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.AvailableStatusId = obj.AvailableStatusId
    this.RoleId = obj.RoleId
    this.AvailableStatus = new NextAvailableStatus(obj.AvailableStatus || {})
    this.Role = new LuRole(obj.Role || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.AvailableStatus = null
    obj.Role = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.AvailableStatus = null
    obj.Role = null
    return obj
  }
}
