import Application from './Application'
import ApplicationDocument from './ApplicationDocument'
export default class HouseholdMember {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //ApplicationId is required
      ApplicationId: 0,
      //IsTaxPayer is required
      IsTaxPayer: false,
      FirstName: null,
      LastName: null,
      NoIncomeTaxFiled: null,
      TaxableIncome: null,
      NonTaxableInterest: null,
      NonTaxableDistributions: null,
      NonTaxableSs: null,
      OtherIncome: null,
      PriorYearPtrsRefund: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.ApplicationId = obj.ApplicationId
    this.IsTaxPayer = obj.IsTaxPayer
    this.FirstName = obj.FirstName
    this.LastName = obj.LastName
    this.NoIncomeTaxFiled = obj.NoIncomeTaxFiled
    this.TaxableIncome = obj.TaxableIncome
    this.NonTaxableInterest = obj.NonTaxableInterest
    this.NonTaxableDistributions = obj.NonTaxableDistributions
    this.NonTaxableSs = obj.NonTaxableSs
    this.OtherIncome = obj.OtherIncome
    this.PriorYearPtrsRefund = obj.PriorYearPtrsRefund
    this.Application = new Application(obj.Application || {})
    this.ApplicationDocuments = []
    if (obj.ApplicationDocuments) {
      obj.ApplicationDocuments.forEach((item) => {
        this.ApplicationDocuments.push(new ApplicationDocument(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Application = null
    obj.ApplicationDocuments = []
    if (this.ApplicationDocuments) {
      this.ApplicationDocuments.forEach((item) => {
        obj.ApplicationDocuments.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Application = null
    obj.ApplicationDocuments = null
    return obj
  }
}
