import LuDocumentType from './LuDocumentType'
import ApplicationDocument from './ApplicationDocument'
import LuTemplateDocument from './LuTemplateDocument'
export default class Document {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //FileName is required
      FileName: '',
      MimeType: null,
      //DocumentTypeId is required
      DocumentTypeId: 0,
      //IsDeleted is required
      IsDeleted: false,
      IsPrinted: false,
      File: null,
      //CreatedById is required
      CreatedById: 0,
      CreatedDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.FileName = obj.FileName
    this.MimeType = obj.MimeType
    this.DocumentTypeId = obj.DocumentTypeId
    this.IsDeleted = obj.IsDeleted
    this.IsPrinted = obj.IsPrinted
    this.File = obj.File
    this.CreatedById = obj.CreatedById
    this.CreatedDate = obj.CreatedDate
    this.CreatedBy = obj.CreatedBy || {}
    this.DocumentType = new LuDocumentType(obj.DocumentType || {})
    this.ApplicationDocuments = []
    if (obj.ApplicationDocuments) {
      obj.ApplicationDocuments.forEach((item) => {
        this.ApplicationDocuments.push(new ApplicationDocument(item))
      })
    }
    this.LuTemplateDocuments = []
    if (obj.LuTemplateDocuments) {
      obj.LuTemplateDocuments.forEach((item) => {
        this.LuTemplateDocuments.push(new LuTemplateDocument(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.CreatedBy = null
    obj.DocumentType = null
    obj.ApplicationDocuments = []
    if (this.ApplicationDocuments) {
      this.ApplicationDocuments.forEach((item) => {
        obj.ApplicationDocuments.push(item.removeRelated())
      })
    }
    obj.LuTemplateDocuments = []
    if (this.LuTemplateDocuments) {
      this.LuTemplateDocuments.forEach((item) => {
        obj.LuTemplateDocuments.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.CreatedBy = null
    obj.DocumentType = null
    obj.ApplicationDocuments = null
    obj.LuTemplateDocuments = null
    return obj
  }
}
