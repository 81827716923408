import CityCounty from './CityCounty'
import TaxableProperty from './TaxableProperty'
import LuCounty from './LuCounty'
export default class LuCity {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Name is required
      Name: '',
      IsActive: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Name = obj.Name
    this.IsActive = obj.IsActive
    this.CityCounties = []
    if (obj.CityCounties) {
      obj.CityCounties.forEach((item) => {
        this.CityCounties.push(new CityCounty(item))
      })
    }
    this.TaxableProperties = []
    if (obj.TaxableProperties) {
      obj.TaxableProperties.forEach((item) => {
        this.TaxableProperties.push(new TaxableProperty(item))
      })
    }
    this.Counties = []
    if (obj.Counties) {
      obj.Counties.forEach((item) => {
        this.Counties.push(new LuCounty(item))
      })
    }
  }
  removeRelated () {
    let obj = Object.assign({}, this)
    obj.CityCounties = []
    if (this.CityCounties) {
      this.CityCounties.forEach((item) => {
        obj.CityCounties.push(item.removeRelated())
      })
    }
    obj.TaxableProperties = []
    if (this.TaxableProperties) {
      this.TaxableProperties.forEach((item) => {
        obj.TaxableProperties.push(item.removeRelated())
      })
    }
    obj.Counties = []
    if (this.Counties) {
      this.Counties.forEach((item) => {
        obj.Counties.push(item instanceof LuCounty ? item.removeRelated() : item)
      })
    }
    return obj
  }
  root () {
    let obj = Object.assign({}, this)
    obj.CityCounties = null
    obj.TaxableProperties = null
    return obj
  }
}
