import LuCity from './LuCity'
import LuCounty from './LuCounty'
export default class CityCounty {
  constructor(objIn = {}) {
    const defaults = {
      //CityId is required
      CityId: 0,
      //CountyId is required
      CountyId: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.CityId = obj.CityId
    this.CountyId = obj.CountyId
    this.City = new LuCity(obj.City || {})
    this.County = new LuCounty(obj.County || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.City = null
    obj.County = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.City = null
    obj.County = null
    return obj
  }
}
