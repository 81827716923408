import Document from './Document'
import StatusTemplate from './StatusTemplate'
export default class LuTemplateDocument {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Description is required
      Description: '',
      //DocumentId is required
      DocumentId: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Description = obj.Description
    this.DocumentId = obj.DocumentId
    this.Document = new Document(obj.Document || {})
    this.StatusTemplates = []
    if (obj.StatusTemplates) {
      obj.StatusTemplates.forEach((item) => {
        this.StatusTemplates.push(new StatusTemplate(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Document = null
    obj.StatusTemplates = []
    if (this.StatusTemplates) {
      this.StatusTemplates.forEach((item) => {
        obj.StatusTemplates.push(item.removeRelated())
      })
    }
    return obj
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.Document = this.Document.root()
    obj.StatusTemplates = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Document = null
    obj.StatusTemplates = null
    return obj
  }
}
