import LuReason from './LuReason'
const defaults = {
  //Id is required
  Id: 0,
  //Description is required
  Description: '',
  //IsActive: false, //sql default
  //IsActive is required
  IsActive: false, //sql default
}

export default class LuValidation {
  constructor(obj = {}) {
    Object.assign(this, defaults, obj)
    this.LuReasons = []
    if (obj.LuReasons) {
      obj.LuReasons.forEach((item) => {
        this.LuReasons.push(new LuReason(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.LuReasons = []
    if (this.LuReasons) {
      this.LuReasons.forEach((item) => {
        obj.LuReasons.push(
          item instanceof LuReason ? item.removeRelated() : item
        )
      })
    }
    return this.equalsDefault() ? null : obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.LuReasons = null
    return this.equalsDefault() ? null : obj
  }
  equalsDefault() {
    let obj = Object.assign({}, this)
    let compare = {
      Id: obj.Id,
      Description: obj.Description,
      IsActive: obj.IsActive,
    }
    return JSON.stringify(defaults) === JSON.stringify(compare)
  }
}
