import LuFundingSource from './LuFundingSource'
export default class LuFundingSponsor {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Description is required
      Description: '',
      //IsActive is required
      IsActive: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Description = obj.Description
    this.IsActive = obj.IsActive
    this.LuFundingSources = []
    if (obj.LuFundingSources) {
      obj.LuFundingSources.forEach((item) => {
        this.LuFundingSources.push(new LuFundingSource(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.LuFundingSources = []
    if (this.LuFundingSources) {
      this.LuFundingSources.forEach((item) => {
        obj.LuFundingSources.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.LuFundingSources = null
    return obj
  }
}
