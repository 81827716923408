import LuFundingSourceType from './LuFundingSourceType'
import LuFundingSponsor from './LuFundingSponsor'
import FundingAppropriation from './FundingAppropriation'
import WolfsItem from './WolfsItem'
export default class LuFundingSource {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Description is required
      Description: '',
      //FundingSourceTypeId is required
      FundingSourceTypeId: 0,
      FundingSponsorId: null,
      //IsActive is required
      IsActive: false,
      //CreatedById is required
      CreatedById: 0,
      //CreatedDate is required
      CreatedDate: new Date().toISOString().split('T')[0],
      //UpdatedById is required
      UpdatedById: 0,
      //UpdatedDate is required
      UpdatedDate: new Date().toISOString().split('T')[0],
      //AgencyCode is required
      AgencyCode: '',
      //FundCode is required
      FundCode: '',
      ObjectCode: null,
      SubObjectCode: null,
      UnitCode: null,
      AppropriationUnit: null,
      ActivityCode: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Description = obj.Description
    this.FundingSourceTypeId = obj.FundingSourceTypeId
    this.FundingSponsorId = obj.FundingSponsorId
    this.IsActive = obj.IsActive
    this.CreatedById = obj.CreatedById
    this.CreatedDate = obj.CreatedDate
    this.UpdatedById = obj.UpdatedById
    this.UpdatedDate = obj.UpdatedDate
    this.AgencyCode = obj.AgencyCode
    this.FundCode = obj.FundCode
    this.ObjectCode = obj.ObjectCode
    this.SubObjectCode = obj.SubObjectCode
    this.UnitCode = obj.UnitCode
    this.AppropriationUnit = obj.AppropriationUnit
    this.ActivityCode = obj.ActivityCode
    this.CreatedBy = obj.CreatedBy || {}
    this.FundingSourceType = new LuFundingSourceType(obj.FundingSourceType || {})
    this.FundingSponsor = new LuFundingSponsor(obj.FundingSponsor || {})
    this.UpdatedBy = obj.UpdatedBy || {}
    this.FundingAppropriations = []
    if (obj.FundingAppropriations) {
      obj.FundingAppropriations.forEach((item) => {
        this.FundingAppropriations.push(new FundingAppropriation(item))
      })
    }
    this.WolfsItems = []
    if (obj.WolfsItems) {
      obj.WolfsItems.forEach((item) => {
        this.WolfsItems.push(new WolfsItem(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.CreatedBy = null
    obj.FundingSourceType = null
    obj.FundingSponsor = null
    obj.UpdatedBy = null
    obj.FundingAppropriations = []
    if (this.FundingAppropriations) {
      this.FundingAppropriations.forEach((item) => {
        obj.FundingAppropriations.push(item.removeRelated())
      })
    }
    obj.WolfsItems = []
    if (this.WolfsItems) {
      this.WolfsItems.forEach((item) => {
        obj.WolfsItems.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.CreatedBy = null
    obj.FundingSourceType = null
    obj.FundingSponsor = null
    obj.UpdatedBy = null
    obj.FundingAppropriations = null
    obj.WolfsItems = null
    return obj
  }
}
