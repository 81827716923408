import ApplicationNote from './ApplicationNote'
export default class LuNoteType {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Description is required
      Description: '',
      //InternalOnly is required
      InternalOnly: false,
      //IsActive is required
      IsActive: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Description = obj.Description
    this.InternalOnly = obj.InternalOnly
    this.IsActive = obj.IsActive
    this.ApplicationNotes = []
    if (obj.ApplicationNotes) {
      obj.ApplicationNotes.forEach((item) => {
        this.ApplicationNotes.push(new ApplicationNote(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.ApplicationNotes = []
    if (this.ApplicationNotes) {
      this.ApplicationNotes.forEach((item) => {
        obj.ApplicationNotes.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.ApplicationNotes = null
    return obj
  }
}
