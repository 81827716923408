import LuCounty from './LuCounty'
import LuPeriod from './LuPeriod'
export default class CountyMedian {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //PeriodId is required
      PeriodId: 0,
      //CountyId is required
      CountyId: 0,
      MedianIncome: null,
      MedianTax: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.PeriodId = obj.PeriodId
    this.CountyId = obj.CountyId
    this.MedianIncome = obj.MedianIncome
    this.MedianTax = obj.MedianTax
    this.County = new LuCounty(obj.County || {})
    this.Period = new LuPeriod(obj.Period || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.County = null
    obj.Period = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.County = null
    obj.Period = null
    return obj
  }
}
