import LuFundingSource from './LuFundingSource'
import LuPeriod from './LuPeriod'
export default class FundingAppropriation {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //FundingSourceId is required
      FundingSourceId: 0,
      //PeriodId is required
      PeriodId: 0,
      //OriginalAmount is required
      OriginalAmount: 0,
      //CurrentAmount is required
      CurrentAmount: 0,
      //CommittedAmount is required
      CommittedAmount: 0,
      //DisbursedAmount is required
      DisbursedAmount: 0,
      //AdministrativeFee is required
      AdministrativeFee: 0,
      //AvailableAmount is required
      AvailableAmount: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.FundingSourceId = obj.FundingSourceId
    this.PeriodId = obj.PeriodId
    this.OriginalAmount = obj.OriginalAmount
    this.CurrentAmount = obj.CurrentAmount
    this.CommittedAmount = obj.CommittedAmount
    this.DisbursedAmount = obj.DisbursedAmount
    this.AdministrativeFee = obj.AdministrativeFee
    this.AvailableAmount = obj.AvailableAmount
    this.FundingSource = new LuFundingSource(obj.FundingSource || {})
    this.Period = new LuPeriod(obj.Period || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.FundingSource = null
    obj.Period = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.FundingSource = null
    obj.Period = null
    return obj
  }
}
